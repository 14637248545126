<template>
    <div>
        <graduate-application-layout>
            <slot></slot>
            <div class="row vh-100 overflow-x-hidden">
                <div class="col-6 pl-0 bg-cover-center ml-minus-5px d-none d-lg-block p-0 login-right-img"
                     v-bind:style="{'background-image':'url(' + require('@/assets/img/login-right-bg.jpg')+')'}">
                    <div class="login-text">
                        {{ $t('graduate_application_form').toUpper() }}
                    </div>
                </div>
                <div class="col-12 col-lg-6 bg-white rounded-lg mr-0 mr-md-minus-5px z-index-1 shadow">
                    <div class="d-flex align-items-start flex-column h-100">
                        <div class="my-auto w-100">
                            <div class="row justify-content-center">
                                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">

                                        <ValidationObserver ref="loginForm">
                                            <div class="row">
                                                <div class="col-12 mb-4 d-flex justify-content-center">
                                                    <div class="logo-wrapper"><img src="../../../../assets/img/bau-logo-for-light.svg"/></div>
                                                </div>
                                                <div class="col-12 mt-4">
                                                    <ValidationProvider name="nationalId" rules="required" v-slot="{valid, errors}">
                                                        <b-form-group :label="$t('national_number')" class="input-icon position-relative">
                                                            <b-form-input
                                                                :type="loginPasswordShowStatus ? 'text':'password'"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                v-model="loginForm.nationalId"></b-form-input>
                                                            <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                                                               @click="loginPasswordShowStatus=true"
                                                               v-if="!loginPasswordShowStatus"></i>
                                                            <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                                                               @click="loginPasswordShowStatus=false"
                                                               v-if="loginPasswordShowStatus"></i>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12">
                                                    <ValidationProvider name="pinCode" rules="required" v-slot="{valid, errors}">
                                                        <b-form-group :label="$t('pin_code')" class="input-icon position-relative">
                                                            <b-form-input
                                                                :type="loginPasswordShowStatusPin ? 'text':'password'"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                v-model="loginForm.pinCode"></b-form-input>
                                                            <i class="ri-eye-close-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                                                               @click="loginPasswordShowStatusPin=true"
                                                               v-if="!loginPasswordShowStatusPin"></i>
                                                            <i class="ri-eye-2-line w-40 h-40 position-absolute d-flex align-items-center justify-content-center right-0 top-0 cursor-pointer"
                                                               @click="loginPasswordShowStatusPin=false"
                                                               v-if="loginPasswordShowStatusPin"></i>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12">
                                                    <b-button @click="login" block variant="primary">{{ $t('login').toUpper() }}</b-button>
                                                </div>
                                                <div class="col-12">
                                                    <b-button @click="toForm" block variant="link">{{$t('application_new').toUpper()}}</b-button>
                                                </div>
                                            </div>
                                        </ValidationObserver>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </graduate-application-layout>




    </div>
</template>
<script>

import {ValidationObserver, ValidationProvider} from "vee-validate";
import GraduateApplicationService from "@/services/GraduateApplicationService";
import GraduateApplicationLayout from "@/layouts/GraduateApplicationLayout";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        GraduateApplicationLayout
    },
    metaInfo() {
        return {
            title: this.$t('graduate_application_form')
        }
    },
    data() {
        return {
            loginForm: {
                nationalId: null,
                pinCode: null
            },
            loginPasswordShowStatus: false,
            loginPasswordShowStatusPin: false,
        }
    },
    created() {

    },
    mounted() {

    },
    watch: {},
    methods: {
        async login() {
            const isValid = await this.$refs.loginForm.validate();
            if(isValid){
                let formData = {
                    national_id: this.loginForm.nationalId,
                    pin: this.loginForm.pinCode
                }
                this.loginForm.nationalId = null
                this.loginForm.pinCode = null
                GraduateApplicationService.show(formData).then(response => {
                    let data = response.data.data
                    let stateData = {
                        application_type: data.application_type,
                        birthdate: data.birthdate,
                        created_at: data.created_at,
                        email: data.email,
                        father_name: data.father_name,
                        gender: data.gender,
                        id: data.id,
                        military_status: data.military_status,
                        mother_name: data.mother_name,
                        name: data.name,
                        national_id: data.national_id,
                        nationality_code: data.nationality_code,
                        phone: data.phone,
                        pin: data.pin,
                        semester_id: data.semester_id,
                        status: data.status,
                        surname: data.surname,
                        updated_at: data.updated_at,
                        yoksis_unit_id: data.yoksis_unit_id,
                        address: data.address,
                        sector: data.sector,
                        step_type: data.step_type,
                        form_status: 'update'
                    }
                    this.$store.dispatch("graduateApplication/kvkkStore");
                    this.$store.dispatch("graduateApplication/restoreLbfPersonalInfo", stateData);
                    this.$store.dispatch("graduateApplication/restoreTab", 0);
                    this.$router.push({name : "graduateApplicationUpdate"})
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.'+e.data.message));
                        }
                    } else {
                        if (e.data.errors.national_id) {
                            this.$refs.loginForm.errors.nationalId.push(e.data.errors.national_id[0]);
                        }
                        if (e.data.errors.pin) {
                            this.$refs.loginForm.errors.pinCode.push(e.data.errors.pin[0]);
                        }
                    }
                })
            }
        },

        toForm(){
            this.$router.push({name: 'graduateApplicationForm'})
        }
    }
};
</script>

